import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Checkbox } from 'antd';

import { getTermsByGroupId, getCheckboxesByGroupId } from './fixture';
import { Term, CheckboxItem, TermsAndConditionsProps } from './interface';
import style from './style.module.css';
import { selectedPackageTypeProduct } from 'store/slices/selectedDataSlice';

const TermsAndConditions = forwardRef<HTMLDivElement, TermsAndConditionsProps>(
  (
    { groupId, termsAndConditionsAgreements, handleCheckboxChange, errors },
    ref
  ) => {
    const selectedPackageProduct = useSelector(selectedPackageTypeProduct);

    const terms: Term[] = getTermsByGroupId(
      groupId,
      selectedPackageProduct?.description_link
    );
    const checkboxes: CheckboxItem[] = getCheckboxesByGroupId(groupId);

    return (
      <div>
        <h2>Geschäftsbedingungen und Konditionen</h2>

        {terms.map((term, index) => (
          <Row className="margin_bottom_sm" key={index}>
            <Col xs={2} lg={1} className={style.term_id}>
              <p>{term.id}.</p>
            </Col>
            <Col xs={22} lg={23} className={style.term_text}>
              <div dangerouslySetInnerHTML={{ __html: term.text }} />
            </Col>
          </Row>
        ))}

        {checkboxes.map((checkbox) => (
          <Row key={checkbox.id} className="margin_bottom_sm">
            <Col span={24}>
              <Checkbox
                className={
                  errors[checkbox.type]
                    ? 'custom_style_checkbox_error'
                    : 'custom_style_checkbox'
                }
                checked={termsAndConditionsAgreements[checkbox.type]}
                onChange={handleCheckboxChange(checkbox.type)}
              >
                <p className={style.checkbox_text}>
                  <span
                    className={
                      errors[checkbox.type]
                        ? 'error_text_style'
                        : 'package_speed'
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: checkbox.label }}
                    />
                  </span>
                </p>
              </Checkbox>
            </Col>
          </Row>
        ))}
        <div ref={ref}></div>
      </div>
    );
  }
);

TermsAndConditions.displayName = 'TermsAndConditions';

export default TermsAndConditions;
