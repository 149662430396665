import {
  CheckboxesByGroup,
  CheckboxItem,
  Term,
  TermsByGroup
} from './interface';

export const TERMS_AND_CONDITIONS: TermsByGroup = {
  group_1: [
    {
      id: '01',
      text: 'Es gelten die allgemeinen Geschäftsbedingungen für Privatkunden diese sind im Internet unter <a href="https://muenet-glasfaser.de/download/agb-privatkunden/" target="_blank">https://muenet-glasfaser.de/download/agb-privatkunden/</a> abrufbar.'
    },
    {
      id: '02',
      text: 'Ich bin jederzeit widerruflich damit einverstanden, dass die MUENET GmbH & Co. KG (nachfolgende "MUENET") zur Bonitätsprüfung Daten mit der SCHUFA / CEG / BÜRGEL bzw. einer Wirtschaftsauskunftei austauscht und meine personenbezogenen Daten im Rahmen der gesetzlichen Bestimmungen erhebt, verarbeitet und nutzt.'
    },
    {
      id: '03',
      text: 'Der Wiederverkauf der Leistungen von MUENET an Dritte ist nicht gestattet. MUENET-Produkte dürfen nur für den Eigengebrauch genutzt werden.'
    },
    {
      id: '04',
      text: 'MUENET beachtet im Umgang mit personenbezogenen Daten die gesetzlichen Vorschriften über den Datenschutz und ist zur Wahrung des Fernmeldegeheimnisses verpflichtet.'
    },
    {
      id: '05',
      text: "MUENET nutzt die E-Mail-Adresse des Auftraggebers für den Versand von Angeboten und hilfreichen Informationen, die für den Auftraggeber von Interesse sein könnten. Der Auftraggeber kann das Einverständnis' zur Nutzung der E-Mail-Adresse zu Werbezwecken jederzeit ohne Angabe von Gründen widerrufen. Um den Widerruf zu erklären, kann der Auftraggeber einfach den Abmeldelink, welcher in jeder E-Mail zu Werbezwecken enthalten ist, nutzen. Für den Widerruf fallen - abgesehen von den Übermittlungskosten nach den Basistarifen - keine Kosten für den Auftraggeber an."
    },
    {
      id: '06',
      text: 'Die Mindestvertragslaufzeit beträgt - sofern nicht anderweitig explizit angegeben - 24 Monate und ist nach den jeweils gesetzlich gültigen Fristen kündbar. Nach der anfänglichen Mindestlaufzeit verlängert sich der Vertrag auf unbestimmte Zeit und ist dann jederzeit mit einer Frist von einem Monat taggenau kündbar. Bei Nutzung von “Internet vorab” beginnt die Vertragslaufzeit mit Inbetriebnahme des regulären Tarifs.'
    },
    {
      id: '07',
      text: 'Der Vertrag kommt mit der schriftlichen Auftragsbestätigung durch MUENET zustande.'
    },
    {
      id: '08',
      text: 'Sofern unter “Rufnummernmitnahme“ eine Kündigung und/oder Portierung ausgewählt ist, erteilt der Auftraggeber der MUENET die Vollmacht zur Kündigung des Alt-Vertrages bzw. zur Portierung der angegebenen, bestehenden Rufnummer(n). Die Kündigung bei dem jetzigen Telefon-Festnetzanbieter durch MUENET wird dringend empfohlen, um sicherzustellen, dass die bisherigen Rufnummern erhalten bleiben und ein lückenloser Übergang vom Altanbieter zu MUENET gewährleistet ist. Eventuelle Zusatzverträge mit Drittanbietern - für Internet, DSL, UMTS, Kabel- oder IP-TV, Router, Preselection usw. - müssen vom Auftraggeber selbst gekündigt werden, da MUENET hier nicht zu einer Kündigung berechtigt ist.'
    },
    {
      id: '09',
      text: 'Die Leistungsgrenze von MUENET ist das Glasfasermodem. Nicht zum Leistungsbereich der MUENET gehören kundeneigene Soft- und Hardware, wie PC´s, WLAN und LAN etc.'
    },
    {
      id: '10',
      text: 'Als Kunden von den hier angebotenen Privatkundenservices kommen ausschließlich volljährige Personen, die Verbraucher gemäß §13 BGB sind, in Frage. Insofern bestätigt der Auftraggeber, dass diese Voraussetzungen erfüllt sind.<br>(Hinweis: Bitte beachten Sie unsere separaten Geschäftskunden-Auftragsformulare für Unternehmer gemäß §14.1 BGB.)'
    }
  ],
  group_2: [
    {
      id: '01',
      text: 'Es gelten die allgemeinen Geschäftsbedingungen für Geschäftskunden diese sind im Internet unter <a href="https://muenet-glasfaser.de/download/agb-business-basic/" target="_blank">https://muenet-glasfaser.de/download/agb-business-basic/</a> abrufbar.'
    },
    {
      id: '02',
      text: 'Ich bin jederzeit widerruflich damit einverstanden, dass die MUENET GmbH & Co. KG (nachfolgende "MUENET") zur Bonitätsprüfung Daten mit der SCHUFA / CEG / BÜRGEL bzw. einer anderen Wirtschaftsauskunftei austauscht und meine personenbezogenen Daten im Rahmen der gesetzlichen Bestimmungen erhebt, verarbeitet und nutzt.'
    },
    {
      id: '03',
      text: 'Der Wiederverkauf der Leistungen von MUENET an Dritte ist nicht gestattet. MUENET-Produkte dürfen nur für den Eigengebrauch genutzt werden.'
    },
    {
      id: '04',
      text: 'MUENET beachtet im Umgang mit personenbezogenen Daten die gesetzlichen Vorschriften über den Datenschutz und ist zur Wahrung des Fernmeldegeheimnisses verpflichtet.'
    },
    {
      id: '05',
      text: "MUENET nutzt die E-Mail-Adresse des Auftraggebers für den Versand von Angeboten und hilfreichen Informationen, die für den Auftraggeber von Interesse sein könnten. Der Auftraggeber kann das Einverständnis' zur Nutzung der E-Mail-Adresse zu Werbezwecken jederzeit ohne Angabe von Gründen widerrufen. Um den Widerruf zu erklären, kann der Auftraggeber einfach den Abmeldelink, welcher in jeder E-Mail zu Werbezwecken enthalten ist, nutzen. Für den Widerruf fallen - abgesehen von den Übermittlungskosten nach den Basistarifen - keine Kosten für den Auftraggeber an."
    },
    {
      id: '06',
      text: 'Die Mindestvertragslaufzeit beträgt - sofern nicht anderweitig explizit angegeben - 60 Monate. Nach der anfänglichen Mindestlaufzeit verlängert sich der Vertrag auf unbestimmte Zeit jeweils um 12 Monate und ist dann jederzeit mit einer Frist von drei Monaten zum Ende eines Vertragsjahres kündbar. Bei Nutzung von “Internet vorab” beginnt die Vertragslaufzeit mit Inbetriebnahme des regulären Tarifs.'
    },
    {
      id: '07',
      text: 'Der Vertrag kommt mit der schriftlichen Auftragsbestätigung durch MUENET zustande.'
    },
    {
      id: '08',
      text: 'Sofern unter “Rufnummernmitnahme“ eine Kündigung und/oder Portierung ausgewählt ist, erteilt der Auftraggeber der MUENET die Vollmacht zur Kündigung des Alt-Vertrages bzw. zur Portierung der angegebenen, bestehenden Rufnummer(n). Die Kündigung bei dem jetzigen Telefon-Festnetzanbieter durch MUENET wird dringend empfohlen, um sicherzustellen, dass die bisherigen Rufnummern erhalten bleiben und ein lückenloser Übergang vom Altanbieter zu MUENET gewährleistet ist. Eventuelle Zusatzverträge mit Drittanbietern - für Internet, DSL, UMTS, Kabel- oder IP-TV, Router, Preselection usw. - müssen vom Auftraggeber selbst gekündigt werden, da MUENET hier nicht zu einer Kündigung berechtigt ist.'
    },
    {
      id: '09',
      text: 'Die Leistungsgrenze von MUENET ist das Glasfasermodem. Nicht zum Leistungsbereich der MUENET gehören kundeneigene Soft- und Hardware, wie PC´s, WLAN und LAN etc.'
    },
    {
      id: '10',
      text: 'Als Kunden von den hier angebotenen Geschäftskundenservices kommen ausschließlich Unternehmer gemäß §14.1 BGB in Frage. Insofern bestätigt der Auftraggeber, dass er Unternehmer gemäß §14.1 BGB ist.'
    }
  ],
  group_3: [
    {
      id: '01',
      text: 'Es gelten die allgemeinen Geschäftsbedingungen für Geschäftskunden diese sind im Internet unter <a href="https://muenet-glasfaser.de/download/agb-business-pro/" target="_blank">https://muenet-glasfaser.de/download/agb-business-pro/</a> abrufbar.'
    },
    {
      id: '02',
      text: 'Ich bin jederzeit widerruflich damit einverstanden, dass die MUENET GmbH & Co. KG (nachfolgende "MUENET") zur Bonitätsprüfung Daten mit der SCHUFA / CEG / BÜRGEL bzw. einer anderen Wirtschaftsauskunftei austauscht und meine personenbezogenen Daten im Rahmen der gesetzlichen Bestimmungen erhebt, verarbeitet und nutzt.'
    },
    {
      id: '03',
      text: 'Der Wiederverkauf der Leistungen von MUENET an Dritte ist nicht gestattet. MUENET-Produkte dürfen nur für den Eigengebrauch genutzt werden.'
    },
    {
      id: '04',
      text: 'MUENET beachtet im Umgang mit personenbezogenen Daten die gesetzlichen Vorschriften über den Datenschutz und ist zur Wahrung des Fernmeldegeheimnisses verpflichtet.'
    },
    {
      id: '05',
      text: "MUENET nutzt die E-Mail-Adresse des Auftraggebers für den Versand von Angeboten und hilfreichen Informationen, die für den Auftraggeber von Interesse sein könnten. Der Auftraggeber kann das Einverständnis' zur Nutzung der E-Mail-Adresse zu Werbezwecken jederzeit ohne Angabe von Gründen widerrufen. Um den Widerruf zu erklären, kann der Auftraggeber einfach den Abmeldelink, welcher in jeder E-Mail zu Werbezwecken enthalten ist, nutzen. Für den Widerruf fallen - abgesehen von den Übermittlungskosten nach den Basistarifen - keine Kosten für den Auftraggeber an."
    },
    {
      id: '06',
      text: 'Die Mindestvertragslaufzeit beträgt - sofern nicht anderweitig explizit angegeben - 60 Monate. Nach der anfänglichen Mindestlaufzeit verlängert sich der Vertrag auf unbestimmte Zeit jeweils um 12 Monate und ist dann jederzeit mit einer Frist von drei Monaten zum Ende eines Vertragsjahres kündbar. Bei Nutzung von “Internet vorab” beginnt die Vertragslaufzeit mit Inbetriebnahme des regulären Tarifs.'
    },
    {
      id: '07',
      text: 'Der Vertrag kommt mit der schriftlichen Auftragsbestätigung durch MUENET zustande.'
    },
    {
      id: '08',
      text: 'Sofern unter “Rufnummernmitnahme“ eine Kündigung und/oder Portierung ausgewählt ist, erteilt der Auftraggeber der MUENET die Vollmacht zur Kündigung des Alt-Vertrages bzw. zur Portierung der angegebenen, bestehenden Rufnummer(n). Die Kündigung bei dem jetzigen Telefon-Festnetzanbieter durch MUENET wird dringend empfohlen, um sicherzustellen, dass die bisherigen Rufnummern erhalten bleiben und ein lückenloser Übergang vom Altanbieter zu MUENET gewährleistet ist. Eventuelle Zusatzverträge mit Drittanbietern - für Internet, DSL, UMTS, Kabel- oder IP-TV, Router, Preselection usw. - müssen vom Auftraggeber selbst gekündigt werden, da MUENET hier nicht zu einer Kündigung berechtigt ist.'
    },
    {
      id: '09',
      text: 'Die Leistungsgrenze von MUENET ist das Glasfasermodem. Nicht zum Leistungsbereich der MUENET gehören kundeneigene Soft- und Hardware, wie PC´s, WLAN und LAN etc.'
    },
    {
      id: '10',
      text: 'Als Kunden von den hier angebotenen Geschäftskundenservices kommen ausschließlich Unternehmer gemäß §14.1 BGB in Frage. Insofern bestätigt der Auftraggeber, dass er Unternehmer gemäß §14.1 BGB ist.'
    }
  ],
  group_4: [
    {
      id: '01',
      text: 'Es gelten die allgemeinen Geschäftsbedingungen für öffentliche Einrichtungen & Vereine diese sind im Internet unter <a href="https://muenet-glasfaser.de/download/agb-oeffentliche-einrichtungen-vereine/" target="_blank">https://muenet-glasfaser.de/download/agb-oeffentliche-einrichtungen-vereine/</a> abrufbar.'
    },
    {
      id: '02',
      text: 'Ich bin jederzeit widerruflich damit einverstanden, dass die MUENET GmbH & Co. KG (nachfolgende "MUENET") zur Bonitätsprüfung Daten mit der SCHUFA / CEG / BÜRGEL bzw. einer anderen Wirtschaftsauskunftei austauscht und meine personenbezogenen Daten im Rahmen der gesetzlichen Bestimmungen erhebt, verarbeitet und nutzt.'
    },
    {
      id: '03',
      text: 'Der Wiederverkauf der Leistungen von MUENET an Dritte ist nicht gestattet. MUENET-Produkte dürfen nur für den Eigengebrauch genutzt werden.'
    },
    {
      id: '04',
      text: 'MUENET beachtet im Umgang mit personenbezogenen Daten die gesetzlichen Vorschriften über den Datenschutz und ist zur Wahrung des Fernmeldegeheimnisses verpflichtet.'
    },
    {
      id: '05',
      text: "MUENET nutzt die E-Mail-Adresse des Auftraggebers für den Versand von Angeboten und hilfreichen Informationen, die für den Auftraggeber von Interesse sein könnten. Der Auftraggeber kann das Einverständnis' zur Nutzung der E-Mail-Adresse zu Werbezwecken jederzeit ohne Angabe von Gründen widerrufen. Um den Widerruf zu erklären, kann der Auftraggeber einfach den Abmeldelink, welcher in jeder E-Mail zu Werbezwecken enthalten ist, nutzen. Für den Widerruf fallen - abgesehen von den Übermittlungskosten nach den Basistarifen - keine Kosten für den Auftraggeber an."
    },
    {
      id: '06',
      text: 'Die Mindestvertragslaufzeit beträgt - sofern nicht anderweitig explizit angegeben - 24 Monate. Nach der anfänglichen Mindestlaufzeit verlängert sich der Vertrag auf unbestimmte Zeit und ist dann jederzeit mit einer Frist von einem Monat taggenau kündbar. Bei Nutzung von “Internet vorab” beginnt die Vertragslaufzeit mit Inbetriebnahme des regulären Tarifs.'
    },
    {
      id: '07',
      text: 'Der Vertrag kommt mit der schriftlichen Auftragsbestätigung durch MUENET zustande.'
    },
    {
      id: '08',
      text: 'Sofern unter “Rufnummernmitnahme“ eine Kündigung und/oder Portierung ausgewählt ist, erteilt der Auftraggeber der MUENET die Vollmacht zur Kündigung des Alt-Vertrages bzw. zur Portierung der angegebenen, bestehenden Rufnummer(n). Die Kündigung bei dem jetzigen Telefon-Festnetzanbieter durch MUENET wird dringend empfohlen, um sicherzustellen, dass die bisherigen Rufnummern erhalten bleiben und ein lückenloser Übergang vom Altanbieter zu MUENET gewährleistet ist. Eventuelle Zusatzverträge mit Drittanbietern - für Internet, DSL, UMTS, Kabel- oder IP-TV, Router, Preselection usw. - müssen vom Auftraggeber selbst gekündigt werden, da MUENET hier nicht zu einer Kündigung berechtigt ist.'
    },
    {
      id: '09',
      text: 'Die Leistungsgrenze von MUENET ist das Glasfasermodem. Nicht zum Leistungsbereich der MUENET gehören kundeneigene Soft- und Hardware, wie PC´s, WLAN und LAN etc'
    },
    {
      id: '10',
      text: 'Als Kunden von den hier angebotenen Services kommen ausschließlich die öffentliche Hand - hier Gebietskörperschaften, Anstalten und Körperschaften des öffentlichen Rechts - sowie gemeinnützige Vereine gemäß §52 AO in Frage. Insofern bestätigt der Auftraggeber, dass er der öffentlichen Hand angehört oder ein anerkannter, gemeinnütziger Verein ist.'
    }
  ]
};

export const CHECKBOXES_BY_GROUP: CheckboxesByGroup = {
  group_1: [
    {
      id: 'checkbox_1',
      label:
        'Ich stimme den <a href="https://muenet-glasfaser.de/download/agb-privatkunden/" target="_blank">AGB</a> und den Leistungsbedingungen zu.',
      type: 'agbTermsAgreement'
    },
    {
      id: 'checkbox_2',
      label:
        'Ich habe die folgenden Dokumente erhalten und stimme ihnen zu: <a href="https://muenet-glasfaser.de/download/widerrufsbelehrung/" target="_blank">Widerrufsbelehrung</a>.',
      type: 'confirmationDlAndRevokeProductsAgreement'
    },
    {
      id: 'checkbox_3',
      label:
        'Ich habe die Vertragszusammenfassung* und die vorvertraglichen Pflichtinformationen* erhalten.',
      type: 'confirmationSummaryOfServices'
    }
  ],
  group_2: [
    {
      id: 'checkbox_1',
      label:
        'Ich stimme den <a href="https://muenet-glasfaser.de/download/agb-business-basic/" target="_blank">AGB</a> und den Leistungsbedingungen zu.',
      type: 'agbTermsAgreement'
    },
    {
      id: 'checkbox_2',
      label:
        'Ich habe die Vertragszusammenfassung* und die vorvertraglichen Pflichtinformationen* erhalten.',
      type: 'confirmationSummaryOfServices'
    }
  ],
  group_3: [
    {
      id: 'checkbox_1',
      label:
        'Ich stimme den <a href="https://muenet-glasfaser.de/download/agb-business-pro/" target="_blank">AGB</a> und den Leistungsbedingungen zu.',
      type: 'agbTermsAgreement'
    },
    {
      id: 'checkbox_2',
      label:
        'Ich habe die Vertragszusammenfassung* und die vorvertraglichen Pflichtinformationen* erhalten.',
      type: 'confirmationSummaryOfServices'
    }
  ],
  group_4: [
    {
      id: 'checkbox_1',
      label:
        'Ich stimme den <a href="https://muenet-glasfaser.de/download/agb-oeffentliche-einrichtungen-vereine/" target="_blank">AGB</a> und den Leistungsbedingungen zu.',
      type: 'agbTermsAgreement'
    },
    {
      id: 'checkbox_2',
      label:
        'Ich habe die folgenden Dokumente erhalten und stimme ihnen zu: <a href="https://muenet-glasfaser.de/download/widerrufsbelehrung/" target="_blank">Widerrufsbelehrung</a>.',
      type: 'confirmationDlAndRevokeProductsAgreement'
    },
    {
      id: 'checkbox_3',
      label:
        'Ich habe die Vertragszusammenfassung* und die vorvertraglichen Pflichtinformationen* erhalten.',
      type: 'confirmationSummaryOfServices'
    }
  ]
};

export const getTermsByGroupId = (
  groupId: string,
  descriptionLink: string = ''
): Term[] => {
  const terms = [
    ...(TERMS_AND_CONDITIONS[groupId] || TERMS_AND_CONDITIONS.group_1)
  ];

  if (descriptionLink) {
    terms.push({
      id: `${terms.length + 1}`,
      text: `Hier finden Sie das zu Ihrem Tarif gehörige <a href="${descriptionLink}" target="_blank">Produktinformationsblatt</a>.`
    });
  }

  return terms;
};

export const getCheckboxesByGroupId = (groupId: string): CheckboxItem[] => {
  return CHECKBOXES_BY_GROUP[groupId] || CHECKBOXES_BY_GROUP.group_1;
};
