import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import { getCategories } from 'store/slices/categoriesSlice';
import { setPackageType } from 'store/slices/selectedDataSlice';
import { setErrorMessage, setErrorPlace } from 'store/slices/errorSlice';
import { postFinalSubmit } from 'store/slices/submitSlice';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import {
  isMobile,
  showMobileBasket,
  setIsMobile
} from 'store/slices/mobileSlice';

import { ServiceType } from 'store/slices/selectedServices';

import { generateFinalSubmitData } from './utils';

import { Button, Col, ConfigProvider, Divider, Row, Spin, Steps } from 'antd';
import ProductsAndServices from 'components/Step/ProductsAndServices/ProductAndServices';
import ExistingConnection from 'components/Step/ExistingConnection';
import Payment from 'components/Step/Payment';
import ConfirmBanner from 'components/ConfirmBanner';
import OrderSummary from 'components/Step/OrderSummary';
import BasketCard from 'components/Basket';
import Location from 'components/Step/Location';
import NoPackageSelected from 'components/NoPackageSelected';
import ErrorComponent from 'components/ErrorComponent';

import validationSchema from '../Step/Payment/validationSchema';

import {
  setExistingConnectionsErrors,
  setIsFormValid,
  setTermsAndConditions,
  setValidationErrors,
  setIsEmailRequired
} from 'store/slices/validationSlice';
import {
  formatValidationErrors,
  getErrorsFromValidation
} from 'store/slices/utils';

import {
  Categories,
  Packages,
  PackageAddressInfo,
  SessionValidityRequestData
} from 'types/bundle';

import existingConnectionValidationSchema, {
  ExistingPhoneNumberValidationData
} from '../Step/ExistingConnection/existingConnectionValidationSchema';

import style from './style.module.css';
import generateValidationSchema from '../Step/OrderSummary/termsAndConditionsValidationSchema';
import useCheckSessionValidityInARData from 'hooks/useCheckSessionValidityInAR';
import InvalidSessionModal from 'components/InvalidSessionModal';
import { selectedIsValidSessionId } from 'store/slices/arSessionSlice';
import { SEND_INVOICE_BY_POST_PRODUCT_ID } from 'constants/Constants';

const REQUIRED_MESSAGE = 'Erforderlich';
const STEP_LOCATION = 0;
const STEP_PRODUCT_AND_SERVICES = 1;
const STEP_EXISTING_CONNECTIONS = 2;
const STEP_ORDER_INFORMATION = 3;
const steps = [
  {
    title: 'Basisdaten',
    content: <Location />
  },
  {
    title: 'Produkte & Services',
    content: <ProductsAndServices />
  },

  {
    title: 'Bestehender Anschluss',
    content: <ExistingConnection />
  },
  {
    title: 'Bestellinformationen',
    content: <Payment />
  },
  {
    title: 'Bestellung abschließen',
    content: <OrderSummary />
  }
];

const { Step } = Steps;

const StepsComponent = () => {
  const dispatch: AppDispatch = useDispatch();

  const { handleCheckSessionValidityInARAction } =
    useCheckSessionValidityInARData();

  const [currentStep, setCurrentStep] = useState(0);
  const [showConfirmBanner, setShowConfirmBanner] = useState(false);
  const isMobileDevice = useSelector(isMobile);
  const showBasketForMobile = useSelector(showMobileBasket);

  const servicesData: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const isInvalidSessionModalVisible = useSelector(
    (state: RootState) => !selectedIsValidSessionId(state)
  );

  const queryParameters = new URLSearchParams(window.location.search);

  const packageType = queryParameters.get('package');
  const kvRegionID = queryParameters.get('project_kv_id');
  const applicantStreet = queryParameters.get('street');
  const applicantTown = queryParameters.get('town');
  const applicantHouseNumber = queryParameters.get('street_no');
  const applicantHouseSuffix = queryParameters.get('street_no_suffix');
  const applicantPostCode = queryParameters.get('postcode');
  const projectArID = queryParameters.get('project_ar_id');
  const addressID = queryParameters.get('ap_id');
  const hashkey = queryParameters.get('hashkey');
  const sessionID = queryParameters.get('session_id');

  const { status, error, data } = useSelector(
    (state: RootState) => state.categories
  );
  const selectedData = useSelector((state: RootState) => state.selectedData);
  const priceState = useSelector((state: RootState) => state.prices);
  const { paymentRequired } = useSelector((state: RootState) => state.iban);

  const isEmailRequired = useSelector(
    (state: RootState) => state.validationErrors.emailRequired
  );

  const filesToUpload = useSelector(
    (state: RootState) => state.fileUpload.filesToUpload
  );

  const currentCategoriesWithProducts = useSelector(
    (state: RootState) => state.categories.categoriesWithProducts
  );

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const packageAddressInfo: PackageAddressInfo = {
    package: packageType,
    address_id: addressID,
    hashkey
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const validateOrderInformation = (): boolean => {
    try {
      validationSchema.validateSync(
        {
          shippingEqualToCustomer: selectedData.shippingData.equalToCustomer,
          installationAddressEqualToCustomer:
            selectedData.clientAddressData.equalToCustomer,
          invoiceEqualToCustomer: selectedData.invoiceData.equalToCustomer,
          propertyOwnerEqualToCustomer:
            selectedData.propertyOwnerData.equalToCustomer,
          contactPersonEqualToCustomer:
            selectedData.contactPersonData.equalToCustomer,
          sepaEqualToCustomer: selectedData.paymentData.equalToCustomer,
          ibanRequired: paymentRequired,
          iban: selectedData.paymentData.iban,
          bic: selectedData.paymentData.bic,
          creditInstitution: selectedData.paymentData.credit_institution,
          sepaPaymentTerms: selectedData.paymentData.accept_payment_terms,
          personalInfo: {
            ...selectedData.personalInfo,
            isEmailRequired,
            customer_group_firm: currentPackage?.customer_group_firm || false
          },
          installationAddressData: selectedData.clientAddressData,
          shippingData: {
            ...selectedData.shippingData,
            customer_group_firm: true // Company field is always visible regardles of currentPackage?.customer_group_firm
          },
          invoiceData: {
            ...selectedData.invoiceData,
            isEmailRequired,
            customer_group_firm: currentPackage?.customer_group_firm || false
          },
          propertyOwnerData: {
            ...selectedData.propertyOwnerData,
            customer_group_firm: true
          },
          contactPersonData: selectedData.contactPersonData,
          paymentData: {
            ...selectedData.paymentData,
            customer_group_firm: currentPackage?.customer_group_firm || false
          }
        },
        { abortEarly: false }
      );

      resetFormValidation();

      return true;
    } catch (errors: any) {
      const validationErrors = formatValidationErrors(
        getErrorsFromValidation(errors)
      );

      dispatch(setValidationErrors(validationErrors));
      dispatch(setIsFormValid(false));

      return false;
    }
  };

  const groupId = `group_${currentPackage?.customer_group_id}`;
  const termsAndConditionsValidationSchema = generateValidationSchema(groupId);

  const validateTermsAndConditions = (): boolean => {
    try {
      termsAndConditionsValidationSchema.validateSync(
        {
          termsAndConditionsAgreements:
            selectedData.termsAndConditionsAgreements
        },
        { abortEarly: false }
      );

      dispatch(setTermsAndConditions({}));
      return true;
    } catch (errors: any) {
      const validationErrors = formatValidationErrors(
        getErrorsFromValidation(errors)
      );

      dispatch(
        setTermsAndConditions(validationErrors.termsAndConditionsAgreements)
      );
      return false;
    }
  };

  const validateExistingConnections = (): boolean => {
    try {
      let existingPhoneNumbersToValidate: ExistingPhoneNumberValidationData[] =
        [];

      if (selectedData.selectedExistingConnection.portMyCurrentPhoneNumber) {
        existingPhoneNumbersToValidate =
          selectedData.selectedExistingConnection.existingPhoneNumber
            .slice(0, 1)
            .map((phone) => ({
              areaCode: phone.areaCode,
              phoneNumber: phone.phoneNumber
            }));
      }

      existingConnectionValidationSchema.validateSync(
        {
          previousProvider:
            selectedData.selectedExistingConnection.previousProvider,
          currentConnectionOwnerEqualToCustomer:
            selectedData.selectedExistingConnection.currentConnectionOwner
              .equalToCustomer,
          currentConnectionOwner: {
            title:
              selectedData.selectedExistingConnection.currentConnectionOwner
                .title,
            name: selectedData.selectedExistingConnection.currentConnectionOwner
              .name,
            lastName:
              selectedData.selectedExistingConnection.currentConnectionOwner
                .lastName,
            company:
              selectedData.selectedExistingConnection.currentConnectionOwner
                .company,
            customer_group_firm: currentPackage?.customer_group_firm || false
          },
          existingPhoneNumbers: existingPhoneNumbersToValidate
        },
        { abortEarly: false }
      );

      dispatch(setExistingConnectionsErrors({}));
      dispatch(setIsFormValid(true));
      return true;
    } catch (errors: any) {
      const validationErrors = formatValidationErrors(
        getErrorsFromValidation(errors)
      );

      const errorKey = Object.keys(validationErrors)[0];
      const errorObject = validationErrors[errorKey];

      if (errorObject.areaCode) {
        errorObject.areaCode = REQUIRED_MESSAGE;
      }

      if (errorObject.phoneNumber) {
        errorObject.phoneNumber = REQUIRED_MESSAGE;
      }

      dispatch(setExistingConnectionsErrors(validationErrors));
      dispatch(setIsFormValid(false));
      return false;
    }
  };

  const isCategoryMainProductSelected = (categoryId: number) => {
    return servicesData?.some(
      (serviceProducts: ServiceType) =>
        serviceProducts?.service?.catID ===
        data?.categories?.find(
          (category: Categories) => category?.id === categoryId
        )?.id
    );
  };

  const handleNextStep = () => {
    let invalidCategoryName: string = '';
    for (const categoryWithProducts of currentCategoriesWithProducts) {
      if (
        categoryWithProducts.contains_products &&
        categoryWithProducts.category.user_selectable &&
        categoryWithProducts?.products[0].main_products?.length > 0
      ) {
        const isCategorySelectionValid = isCategoryMainProductSelected(
          categoryWithProducts?.category?.id
        );
        if (!isCategorySelectionValid) {
          invalidCategoryName = categoryWithProducts?.category?.name;
          break;
        }
      }
    }

    if (currentStep === STEP_PRODUCT_AND_SERVICES && invalidCategoryName) {
      dispatch(setErrorPlace(invalidCategoryName));
      dispatch(
        setErrorMessage(
          'Bitte wählen Sie eine der unten stehenden Optionen aus:'
        )
      );
    } else if (currentStep === STEP_ORDER_INFORMATION) {
      const isOrderInformationValid = validateOrderInformation();

      if (isOrderInformationValid) {
        setCurrentStep(currentStep + 1);
        window.scrollTo({ top: 10, left: 0, behavior: 'auto' });
      }
    } else if (currentStep === STEP_EXISTING_CONNECTIONS) {
      dispatch(setIsFormValid(true));
      dispatch(setExistingConnectionsErrors({}));

      const shouldValidateExistingConnections =
        selectedData.selectedExistingConnection.cancelMyCurrentPlan ||
        selectedData.selectedExistingConnection.portMyCurrentPhoneNumber;

      if (shouldValidateExistingConnections) {
        const isExistingConnectionsValid = validateExistingConnections();

        if (isExistingConnectionsValid) {
          setCurrentStep(currentStep + 1);
          window.scrollTo({ top: 10, left: 0, behavior: 'auto' });
        }
      } else {
        setCurrentStep(currentStep + 1);
        window.scrollTo({ top: 10, left: 0, behavior: 'auto' });
      }
    } else {
      setCurrentStep(currentStep + 1);
      window.scrollTo({ top: 10, left: 0, behavior: 'auto' });
    }
  };

  const onNextClick = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    if (
      filesToUpload.length > 0 &&
      selectedData.agentFilesData.uploadedFiles.length === 0
    ) {
      if (STEP_LOCATION === currentStep) {
        await dispatch(setErrorMessage(''));
        dispatch(setErrorMessage('There are files left to upload.'));
      }
    } else {
      dispatch(setErrorMessage(''));
      handleNextStep();
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  };

  const prevStepHandler = () => {
    resetFormValidation();

    setCurrentStep(currentStep - 1);
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  };

  const handleSubmitData = () => {
    const hasAgreedToTermsAndConditions = validateTermsAndConditions();

    if (hasAgreedToTermsAndConditions) {
      const submitPayload = generateFinalSubmitData(
        selectedData,
        priceState,
        applicantStreet as string,
        applicantTown as string,
        applicantHouseNumber as string,
        applicantHouseSuffix as string,
        applicantPostCode as string,
        kvRegionID as string,
        projectArID as string,
        addressID as string,
        hashkey as string,
        sessionID as string,
        packageType as string,
        paymentRequired as boolean,
        servicesData as ServiceType[],
        currentPackage as Packages
      );

      dispatch(postFinalSubmit(submitPayload.submitData as any));
      setShowConfirmBanner(true);
    }
  };

  const pdfSubmitPayloadData = useMemo(() => {
    if (currentStep === 4) {
      return generateFinalSubmitData(
        selectedData,
        priceState,
        applicantStreet as string,
        applicantTown as string,
        applicantHouseNumber as string,
        applicantHouseSuffix as string,
        applicantPostCode as string,
        kvRegionID as string,
        projectArID as string,
        addressID as string,
        hashkey as string,
        sessionID as string,
        packageType as string,
        paymentRequired as boolean,
        servicesData as ServiceType[],
        currentPackage as Packages
      );
    }
  }, [currentStep]);

  useEffect(() => {
    const initialize = async () => {
      try {
        dispatch(
          getCategories({ region: kvRegionID, selectedPackage: packageType })
        );

        dispatch(setPackageType(packageType));

        const sessionValidityData: SessionValidityRequestData = {
          hashkey: hashkey || '',
          session_id: sessionID || '',
          address_id: addressID || ''
        };
        await handleCheckSessionValidityInARAction(sessionValidityData);
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    };

    initialize();

    const handleResize = () => {
      if (window.innerWidth <= 800) {
        dispatch(setIsMobile(true));
      } else {
        dispatch(setIsMobile(false));
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch, kvRegionID, packageType]);

  useEffect(() => {
    const findSendByPostProduct = () => {
      const postProduct = servicesData.find(
        (product: ServiceType) =>
          product?.service?.productID === SEND_INVOICE_BY_POST_PRODUCT_ID
      );

      if (postProduct) {
        dispatch(setIsEmailRequired(false));
      } else {
        dispatch(setIsEmailRequired(true));
      }
    };

    findSendByPostProduct();
  }, [servicesData]);

  const resetFormValidation = () => {
    dispatch(setValidationErrors({}));
    dispatch(setIsFormValid(true));
  };

  const renderTitle = (index: number) => {
    if (index === currentStep) {
      return steps[index].title;
    }
    return null;
  };

  if (status === 'loading') {
    return <Spin fullscreen tip="Loading" />;
  }

  if (!packageType) {
    return <NoPackageSelected />;
  }

  if (error && status === 'failed') {
    return <ErrorComponent />;
  }

  if (showBasketForMobile) {
    return <BasketCard fullScreen={true} />;
  }

  return (
    <div className={style.steps_container}>
      <div className={style.content_container}>
        {showConfirmBanner ? (
          <ConfirmBanner payloadData={pdfSubmitPayloadData?.submitData} />
        ) : (
          <>
            <div className={style.steps_wrapper}>
              <ConfigProvider
                theme={{
                  components: {
                    Steps: {
                      iconSize: 40
                    }
                  }
                }}
              >
                <Steps
                  current={currentStep}
                  labelPlacement="vertical"
                  direction="horizontal"
                >
                  {items.map((item, index) => (
                    <Step
                      key={item.title}
                      title={isMobileDevice ? renderTitle(index) : item.title}
                      className={style.step}
                    />
                  ))}
                </Steps>
                <InvalidSessionModal
                  isVisible={isInvalidSessionModalVisible}
                  packageAddressInfo={packageAddressInfo}
                />
              </ConfigProvider>
            </div>

            <Row gutter={{ xs: 0, lg: 24 }}>
              {currentStep !== 0 && currentStep !== 4 && (
                <Col lg={16}>
                  <Divider className={style.accordion_divider} />
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={16}>
                {steps[currentStep].content}
              </Col>
              {!isMobileDevice && (
                <Col sm={0} md={0} lg={8}>
                  <BasketCard fullScreen={false} currentStep={currentStep} />
                </Col>
              )}
            </Row>

            <div className={style.navigation_buttons}>
              {currentStep !== 0 && (
                <Button className={style.prev_button} onClick={prevStepHandler}>
                  Zurück
                </Button>
              )}
              {currentStep < 4 ? (
                <Button className={style.next_button} onClick={onNextClick}>
                  Weiter
                </Button>
              ) : (
                <Button
                  className={style.next_button}
                  onClick={handleSubmitData}
                >
                  Kostenpflichtig bestellen
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StepsComponent;
