import { useMemo, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { RootState, AppDispatch } from 'store/store';

import { ProductPros } from './interface';

import { isMobile } from 'store/slices/mobileSlice';

import {
  setSelectedServices,
  ServiceType,
  addShippingPrice,
  removeShippingPrice
} from 'store/slices/selectedServices';

import { Col, Divider, Row, Radio, Checkbox, CheckboxProps } from 'antd';
import FloatingSelect from 'components/FloatingLabelSelect';

import style from './style.module.css';
import 'styles/global.css';
import { Products } from 'types/bundle';
import { SHIPPING_PRODUCT_ID } from 'constants/Constants';
import { formatPrice } from 'components/Step/utils';

const Product = ({
  product,
  productSelected,
  radio,
  checkbox,
  isInternetProduct = false
}: ProductPros) => {
  const dispatch: AppDispatch = useDispatch();

  const mobileDevice = useSelector(isMobile);

  const servicesData = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const shippingProduct: Products = useSelector(
    (state: RootState) => state.shippingProduct.shippingProduct
  );

  const sticks = Array.from(
    { length: product?.max_amount || 0 },
    (_, index) => ({
      value: index + 1,
      label: `${index + 1}`
    })
  );

  const handleAmount = (value: number, productId: number) => {
    const updatedServices = servicesData?.map((obj: ServiceType) => {
      if (obj?.service?.productID === productId) {
        return { ...obj, amount: value };
      }
      return obj;
    });

    dispatch(setSelectedServices(updatedServices));
  };

  const handleAdditionalProductSelect: CheckboxProps['onChange'] = (e) => {
    const { value } = e.target;

    const isProductSelected = servicesData.some(
      (product) => product?.service?.productID === value.productID
    );
    ``;
    if (isProductSelected) {
      const filteredProducts = servicesData.filter(
        (product: ServiceType) =>
          product?.service?.productID !== value.productID
      );

      dispatch(setSelectedServices(filteredProducts));
      dispatch(removeShippingPrice());
    } else {
      dispatch(
        setSelectedServices([...servicesData, { service: value, amount: 1 }])
      );

      const isShippingProduct =
        value?.mandatory_products[0] === SHIPPING_PRODUCT_ID;
      if (isShippingProduct) {
        dispatch(addShippingPrice({ service: shippingProduct, amount: 1 }));
      }
    }
  };

  const checkboxProductSelected = useMemo(() => {
    return servicesData.some(
      (selectedServices: ServiceType) =>
        selectedServices?.service?.productID === product?.productID
    );
  }, [servicesData, product]);

  const setIncludedProducts = () => {
    const productExists = servicesData.some(
      (obj: ServiceType) => obj.service?.productID === product?.productID
    );

    if (!productExists && product?.option === false) {
      dispatch(
        setSelectedServices([...servicesData, { service: product, amount: 1 }])
      );
    }
  };

  useEffect(() => {
    setIncludedProducts();
  }, [product, servicesData]);

  return (
    <div
      className={
        product?.marker_type === 1
          ? 'yellow-highlight'
          : productSelected || checkboxProductSelected
            ? 'package_card_wrapper_selected'
            : 'package_card_wrapper'
      }
    >
      <Row>
        {radio && (
          <Col
            xs={{ span: 18, order: 1 }}
            lg={product?.max_amount > 1 ? 8 : 20}
          >
            {product?.option ? (
              <Radio value={product.productID}>
                <p className="package_name">{product.name}</p>
              </Radio>
            ) : (
              <p className="package_name">{product.name}</p>
            )}
          </Col>
        )}

        {checkbox && (
          <Col
            xs={{ span: 18, order: 1 }}
            lg={product?.max_amount > 1 ? (product.image ? 8 : 12) : 18}
          >
            {product?.option ? (
              <Checkbox
                className="custom_style_checkbox"
                onChange={handleAdditionalProductSelect}
                value={product}
                checked={servicesData.some(
                  (services) =>
                    services?.service?.productID === product?.productID
                )}
              >
                <p className="package_name">{product.name}</p>
              </Checkbox>
            ) : (
              <p className="package_name">{product.name}</p>
            )}
          </Col>
        )}

        {product?.max_amount > 1 && (
          <Col
            xs={{ span: 24, order: 3 }}
            lg={{ span: 12, order: 2 }}
            className={mobileDevice ? 'margin_top_md' : ''}
          >
            <FloatingSelect
              value={
                servicesData.find(
                  (obj: ServiceType) =>
                    obj?.service?.productID === product?.productID
                )?.amount || 1
              }
              className={style.additional_stick_select}
              onChangeHandler={(value) => {
                handleAmount(parseInt(value), product?.productID);
              }}
              options={sticks}
              disabled={
                !servicesData.some(
                  (services) =>
                    services?.service?.productID === product?.productID
                )
              }
              label="Anzahl: "
              defaultValue={1}
            />
          </Col>
        )}

        {product.image && (
          <Col
            xs={{ span: 6, order: 2, offset: 0 }}
            md={{ span: 4, offset: product?.max_amount > 1 ? 1 : 0 }}
            lg={{ span: product?.catID === 2 ? 4 : 3, order: 3 }}
          >
            <img
              src={product.image}
              alt="package_type_logo"
              className="product_image"
            />
          </Col>
        )}
      </Row>

      <Row className={'margin_top_md'}>
        <Col
          span={24}
          dangerouslySetInnerHTML={{
            __html: product.description_long
          }}
          className={style.description_text}
        />
      </Row>

      <Divider />

      <Row justify={'space-between'}>
        <Col xxl={16} xs={8}>
          {product.description_link && (
            <a
              className="show_more"
              href={product.description_link}
              target="_blank"
              rel="noreferrer"
            >
              {isInternetProduct ? 'Produktinformationsblatt' : 'Mehr erfahren'}
            </a>
          )}
        </Col>
        <Col xxl={8} xs={16}>
          <p className="package_price">
            {product?.offer_duration
              ? product.price_offer.toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              : product?.price_standard.toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}{' '}
            € {product.monthly_billing && '/ Monat'}
          </p>

          {product?.offer_duration !== 0 && (
            <p className="shipping_price_label">
              {`Ab dem ${product?.offer_duration + 1}`}. Monat:{' '}
              <b>
                {product?.price_standard.toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}{' '}
                € / Monat
              </b>
            </p>
          )}
        </Col>
      </Row>

      {product?.mandatory_products[0] === SHIPPING_PRODUCT_ID && (
        <Row justify="end">
          <Col span={12}>
            <p className="shipping_price_label">
              + Versandkosten{' '}
              {formatPrice(
                shippingProduct?.price_offer ?? shippingProduct?.price_standard
              )}{' '}
              €
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Product;
