import { useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectedCharity,
  setCharity,
  selectedPackageType,
  setPackageTypeProduct,
  selectedAgentFilesData,
  setAgentFilesData
} from 'store/slices/selectedDataSlice';

import { getCharities } from 'store/slices/donationsSlice';

import {
  setSelectedServices,
  ServiceType
} from 'store/slices/selectedServices';

import { Packages, Products, CategoryProducts } from 'types/bundle';

import { Checkbox, Col, Divider, Row, Select } from 'antd';

import { ReactComponent as AddressIcon } from 'assets/images/svg/address_icon.svg';
import { ReactComponent as ProjectIcon } from 'assets/images/svg/icon_project.svg';
import { ReactComponent as CustomerGroupIcon } from 'assets/images/svg/icon_client_group.svg';
import GlobeIcon from 'assets/images/svg/globe_icon.svg';

import { useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RootState } from '../../../store/store';
import { formatAddress, formatPrice } from '../utils';
import DragAndDropFileInput from 'components/DragAndDropFileInput';
import FloatingLabelInput from 'components/FloatingLabelInput';
import { UploadFile } from 'antd/es/upload';
import {
  postFileUpload,
  resetFileUploadState,
  setCurrentlyUploadingFile,
  setFilesToUpload
} from 'store/slices/fileUploadSlice';
import useShortcutListener from 'components/Shortcuts/shortcutListener';
import { Shortcut } from 'components/Shortcuts';
import {
  ShowedMessage,
  StatefulUploadFile
} from 'components/DragAndDropFileInput/interface';
import FloatingLabelSelect from 'components/FloatingLabelSelect';
import { SALES_CHANNELS } from './fixture';

import style from './style.module.css';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import LocationInfoItem from './LocationDataItem';

const queryParameters = new URLSearchParams(window.location.search);

const Location = () => {
  const dispatch = useDispatch();

  const componentRef = useRef<HTMLDivElement | null>(null);

  const { data: charityData } = useSelector(
    (state: RootState) => state.donationCharities
  );

  const { data } = useSelector((state: RootState) => state.categories);
  const packageType = useSelector(selectedPackageType);
  const agentFilesData = useSelector(selectedAgentFilesData);

  const street = queryParameters.get('street') || '';
  const streetNumber = queryParameters.get('street_no') || '';
  const streetNumberSuffix = queryParameters.get('street_no_suffix') || '';
  const postcode = queryParameters.get('postcode') || '';
  const town = queryParameters.get('town') || '';
  const kvRegionID = queryParameters.get('project_kv_id');

  const categories = useSelector((state: RootState) => state.categories);
  const fileUploadData = useSelector((state: RootState) => state.fileUpload);
  const errorMessage = useSelector(
    (state: RootState) => state.errors.errorMessage
  );

  const region = categories?.data?.name || '';

  const selectedCharityData = useSelector(selectedCharity);
  const selectedData = useSelector((state: RootState) => state.selectedData);

  const servicesData: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const [agentId, setAgentId] = useState<number | null>(null);
  const [uploadFiles, setUploadFiles] = useState<StatefulUploadFile[]>([]);
  const [numToBeUploaded, setNumToBeUploaded] = useState<number>(0);
  const [uploadedStatuses, setUploadedStatuses] = useState<string[]>([]);

  const [showedMessage, setShowedMessage] = useState<ShowedMessage | null>(
    null
  );

  const [salesChannel, setSelectedSalesChannel] = useState<string>('');

  const [showAgentForm, setShowAgentForm] = useState<boolean>(false);

  const [isChecked, setChecked] = useState(false);

  const [donationOrganization, setDonationOrganization] =
    useState(selectedCharityData);

  const selectedPackage = useMemo(() => {
    return data?.packages?.find((pkg: Packages) => pkg?.name === packageType);
  }, [data, packageType]);

  const combinedProducts = useMemo(() => {
    const categoryProducts: CategoryProducts[] = Object.values(
      selectedPackage?.products_by_category || []
    );

    return categoryProducts.flatMap(
      ({ additional_products, main_products }: any) => {
        const combinedArray: Products[] = [];

        if (additional_products) {
          combinedArray.push(...additional_products);
        }
        if (main_products) {
          combinedArray.push(...main_products);
        }
        return combinedArray;
      }
    );
  }, [selectedPackage]);

  const selectedPackageProduct: Products | undefined = useMemo(() => {
    return combinedProducts.find(
      (products: Products) =>
        products?.productID === selectedPackage?.tarif_product_ID
    );
  }, [combinedProducts]);

  useShortcutListener(Shortcut.COMMAND_SHIFT_S, () => setShowAgentForm(true));

  const charitiesList = useMemo(() => {
    if (
      Object.keys(charityData)?.length > 0 &&
      charityData?.donation_organisations?.length > 0
    ) {
      return charityData?.donation_organisations.map((charity: string) => ({
        label: charity,
        value: charity
      }));
    } else {
      return [];
    }
  }, [charityData]);

  const salesChannelChangeHandler = (value: string) => {
    setSelectedSalesChannel(value);

    dispatch(
      setAgentFilesData({
        ...agentFilesData,
        salesChannel: value
      })
    );
  };

  const onAgentIdChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const agentId = parseInt(value.replace(/\s+/g, ''));

    setAgentId(agentId);
    dispatch(
      setAgentFilesData({
        ...agentFilesData,
        agentId
      })
    );
    sessionStorage.setItem('agentId', agentId.toString());

    if (value) {
      setSelectedSalesChannel(SALES_CHANNELS[4].value);
    }

    dispatch(
      setAgentFilesData({
        ...agentFilesData,
        salesChannel,
        agentId
      })
    );
  };

  useEffect(() => {
    dispatch(setFilesToUpload(uploadFiles));
  }, [uploadFiles]);

  useEffect(() => {
    if (errorMessage) {
      setShowedMessage({
        message: `Bitte bestätigen Sie das Hochladen der Datei(en) um fortzufahren!`,
        status: 'error'
      });
    } else {
      setShowedMessage(null);
    }
  }, [errorMessage, selectedData.agentFilesData.uploadedFiles.length]);

  useEffect(() => {
    if (selectedData.agentFilesData.uploadedFiles.length) {
      setShowedMessage({
        message: `Sie haben bereits ${selectedData.agentFilesData.uploadedFiles.length} Dateien hochgeladen. Wenn Sie eine weitere Datei hochladen, wird der Prozess neu gestartet. Die bisherigen Dateien werden damit gelöscht und alle gewünschten Dateien müssen erneut hochgeladen werden.`,
        status: 'warning'
      });
    }

    if (showAgentForm && componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    selectedData.agentFilesData.salesChannel &&
      setSelectedSalesChannel(selectedData.agentFilesData.salesChannel);

    if (!selectedData.agentFilesData.salesChannel) {
      setSelectedSalesChannel(SALES_CHANNELS[1].value);
      dispatch(
        setAgentFilesData({
          ...agentFilesData,
          salesChannel
        })
      );
    }
  }, [componentRef.current, showAgentForm]);

  useEffect(() => {
    const { status } = fileUploadData;
    if (status === 'succeeded') {
      setUploadedStatuses((prev: string[]) => [...prev, 'succeeded']);
    } else if (status === 'failed') {
      setUploadedStatuses((prev: string[]) => [...prev, 'failed']);
    }
  }, [fileUploadData.status]);

  useEffect(() => {
    if (uploadedStatuses.length > 0) {
      if (numToBeUploaded === uploadedStatuses.length) {
        if (uploadedStatuses.every((status) => status === 'succeeded')) {
          setShowedMessage({
            message: 'Datei erfolgreich hochgeladen.',
            status: 'success'
          });
        } else {
          const numOfErrors = uploadFiles.filter(
            (file) => file.state === 'failed'
          ).length;
          const errorMessageWithFailNum =
            numOfErrors > 1
              ? `${numOfErrors} Dateien konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.`
              : fileUploadData.error || '';

          if (fileUploadData.error?.includes('415') && numOfErrors === 1) {
            setShowedMessage({
              message: 'Dieser Dateityp ist unzulässig!',
              status: 'error'
            });
          } else if (
            fileUploadData.error?.includes('400') &&
            numOfErrors === 1
          ) {
            setShowedMessage({
              message: 'Dies ist eine potentiell bösartige Datei!',
              status: 'error'
            });
          } else {
            setShowedMessage({
              message: errorMessageWithFailNum
                ? errorMessageWithFailNum
                : 'Dieser Dateityp ist unzulässig!',
              status: 'error'
            });
          }
        }
      }
    }
  }, [uploadedStatuses, numToBeUploaded]);

  const handlePrepareForUpload = (numToBeUploaded: number) => {
    setUploadedStatuses([]);
    setNumToBeUploaded(numToBeUploaded);
  };

  const handleFileUpload = (file: UploadFile) => {
    dispatch(setCurrentlyUploadingFile(file.uid));
    dispatch(
      postFileUpload({
        file
      }) as any
    );
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setChecked(checked);
    if (!checked) {
      setDonationOrganization('');
      dispatch(setCharity(''));
    }
  };

  const handleCharitySelect = (value: string) => {
    setDonationOrganization(value);
    dispatch(setCharity(value));
  };

  useEffect(() => {
    if (['failed', 'succeeded', 'loading'].includes(fileUploadData.status)) {
      const { currentlyUploadingFileId, data, status } = fileUploadData;
      const currentlyUploadingFile = uploadFiles.find(
        (file) => file.uid === currentlyUploadingFileId
      );
      if (currentlyUploadingFile) {
        currentlyUploadingFile.state = fileUploadData.status;
        if (status === 'succeeded') {
          const { fileId } = data;
          const { uploadedFiles } = agentFilesData;
          currentlyUploadingFile.generatedId = fileId;
          dispatch(
            setAgentFilesData({
              ...agentFilesData,
              uploadedFiles: [...uploadedFiles, fileId]
            })
          );
        }
        const newFileList = uploadFiles.map((file) => {
          if (file.uid === currentlyUploadingFileId)
            return currentlyUploadingFile;
          return file;
        });

        setUploadFiles([...newFileList]);
      }
    }
  }, [fileUploadData.status]);

  useEffect(() => {
    const uploadedFiles = uploadFiles.filter(
      (file) => file.state === 'succeeded'
    );
    dispatch(
      setAgentFilesData({
        ...agentFilesData,
        uploadedFiles: uploadedFiles.map((file) => file.generatedId)
      })
    );
    uploadFiles.length === 0 && dispatch(resetFileUploadState());

    uploadFiles.length === selectedData.agentFilesData.uploadedFiles.length &&
      setShowedMessage(null);
  }, [uploadFiles]);

  useEffect(() => {
    const agentId = Number(sessionStorage.getItem('agentId'));

    setAgentId(agentId || null);
    dispatch(
      setAgentFilesData({
        agentId,
        uploadedFiles: selectedData.agentFilesData.uploadedFiles
      })
    );
    dispatch(resetFileUploadState());
  }, []);

  useEffect(() => {
    if (
      !servicesData.find(
        (serviceProducts: ServiceType) =>
          serviceProducts?.service?.productID ===
          selectedPackageProduct?.productID
      )?.service?.productID
    ) {
      dispatch(setPackageTypeProduct(selectedPackageProduct));

      dispatch(
        setSelectedServices([{ service: selectedPackageProduct, amount: 1 }])
      );
    }
  }, [packageType]);

  useEffect(() => {
    dispatch(getCharities(kvRegionID));
  }, [kvRegionID]);

  return (
    <div className={style.location_step_container}>
      <h2 className={style.location_title}>Basisdaten</h2>

      <div className={style.location_wrapper}>
        <LocationInfoItem
          icon={<AddressIcon />}
          text={formatAddress(
            street,
            streetNumber,
            streetNumberSuffix,
            postcode,
            town
          )}
        />
        <LocationInfoItem icon={<ProjectIcon />} text={`Projekt: ${region}`} />
        <LocationInfoItem
          icon={<CustomerGroupIcon />}
          text={`Kundengruppe: ${currentPackage?.customer_group_title}`}
        />
      </div>

      <h2 className={style.location_title}>Ausgewählter Tarif</h2>

      <Row className={style.package_wrapper}>
        <div className="package_info_wrapper">
          <Col className={style.section_icon}>
            <img src={GlobeIcon} alt="globe_icon" />
          </Col>
          <Col
            span={24}
            dangerouslySetInnerHTML={{
              __html: selectedPackageProduct?.description || ''
            }}
            className={style.package_name}
          />
        </div>

        <Row>
          <Col
            span={24}
            dangerouslySetInnerHTML={{
              __html: selectedPackageProduct?.description_long || ''
            }}
            className={style.description_text}
          />
        </Row>

        <Divider />

        <Row justify="space-between" className={style.price_wrapper}>
          <Col span={8}>
            {selectedPackageProduct?.description_link && (
              <a
                className="show_more"
                href={selectedPackageProduct?.description_link}
                target="_blank"
                rel="noreferrer"
              >
                Produktinformationsblatt
              </a>
            )}
          </Col>
          {selectedPackageProduct && (
            <Col span={14}>
              <p className="package_price">
                {formatPrice(
                  selectedPackageProduct?.price_offer ??
                    selectedPackageProduct?.price_standard ??
                    ''
                )}{' '}
                € {selectedPackageProduct?.monthly_billing && '/ Monat'}
              </p>

              {selectedPackageProduct?.offer_duration !== 0 && (
                <p className="shipping_price_label">
                  {`Ab dem ${selectedPackageProduct?.offer_duration + 1}`}.
                  Monat:{' '}
                  <b>
                    {formatPrice(selectedPackageProduct?.price_standard ?? '')}{' '}
                    € / Monat
                  </b>
                </p>
              )}
            </Col>
          )}
        </Row>
      </Row>
      {charityData?.donation && (
        <Row className={style.donation_section_wrapper}>
          <Row gutter={[0, 16]}>
            <Col span={24} className={style.location_title}>
              Spende
            </Col>
            <Col span={24} className={style.section_description}>
              MUENET spendet {`${charityData?.donation_value ?? null} €`} pro
              abgeschlossenem Vertrag an einen Verein Ihrer Wahl.
            </Col>
          </Row>

          <Row className={style.donation_wrapper}>
            <Row
              justify="start"
              align={window.innerWidth <= 800 ? 'middle' : 'top'}
            >
              <Col xs={2} md={1}>
                <Checkbox
                  onChange={handleCheckboxChange}
                  className="custom_style_checkbox"
                  checked={selectedCharityData ? true : isChecked}
                ></Checkbox>
              </Col>

              <Col xs={{ offset: 1, span: 21 }} xl={{ offset: 0, span: 11 }}>
                <p className={style.donation_description}>
                  Gerne soll MUENET {`${charityData?.donation_value ?? null} €`}{' '}
                  an den folgenden Verein für meinen Vertragsabschluss spenden.
                </p>
              </Col>
              <Col xs={24} xl={{ offset: 1, span: 10 }}>
                <Select
                  className={style.donation_select}
                  popupClassName={'charity_select_dropdown'}
                  placeholder="Wählen Sie Ihren Verein aus."
                  disabled={!isChecked}
                  options={charitiesList}
                  onChange={handleCharitySelect}
                  showSearch
                  value={
                    donationOrganization !== ''
                      ? donationOrganization
                      : undefined
                  }
                />
              </Col>
            </Row>
            <Divider />
            <Row justify={'space-between'} className={style.card_footer}>
              <Col span={12}>
                <a
                  className="show_more"
                  href={
                    'https://www.muenet-glasfaser.de/orte/oberbergischer-kreis/morsbach/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Mehr erfahren
                </a>
              </Col>
              <Col span={12}>
                <p className={style.price_description}>
                  Für Sie entstehen keine weiteren Kosten.
                </p>
              </Col>
            </Row>
          </Row>
        </Row>
      )}

      {showAgentForm && (
        <>
          <h2 className={style.location_title}>Verkaufspartner:</h2>

          <Row
            align="middle"
            className={style.location_wrapper}
            ref={componentRef}
          >
            <Row
              justify="space-between"
              className={style.donation_input_wrapper}
            >
              <Col className={style.partner_id_input}>
                <FloatingLabelInput
                  label="Verkaufspartner ID eingeben"
                  value={agentId as number}
                  onChangeHandler={onAgentIdChangeHandler}
                  name="agent_id"
                  errorStatus={''}
                  errorMessage={''}
                  type="number"
                />
              </Col>
              <Col className={style.sales_channel_input}>
                <FloatingLabelSelect
                  label="Vertriebskanal"
                  value={salesChannel}
                  options={SALES_CHANNELS}
                  onChangeHandler={salesChannelChangeHandler}
                />
              </Col>
            </Row>
            <Col span={24}>
              <DragAndDropFileInput
                files={uploadFiles}
                maxCount={10}
                multiple={true}
                name={'upload_file'}
                accept={'.pdf,.jpeg,.jpg'}
                setFiles={setUploadFiles}
                description={
                  '(PDF, JPG, PNG unterstützt - Maximal 10 MB pro Datei)'
                }
                handleUpload={handleFileUpload}
                showedMessage={showedMessage}
                setShowedMessage={setShowedMessage}
                prepareForUpload={handlePrepareForUpload}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Location;
